import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Projects from "./Projects";
import Footer from "./footer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
          <div className={"font-face-Menlo pt-3 pl-5 md:pl-20 pb-8 text-3xl md:text-4xl"}>Experience</div>
          <div className={"grid grid-rows-1 lg:grid-cols-2 xl:grid-cols-3 md:pl-20 md:w-fit gap-5 lg:gap-35 xl:gap-25"}>

              <div className={"justify-self-center mr-0 hover:z-10"}>
                  <Projects className={""}
                            projectName={"Bioinformatics Programmer @ UCSF"}
                            projectInfo={"Learned codebases of multiple Flask projects to modernize the UI/UX, and refactor source code. Next.js, Used React, and TailwindCSS to create reusable component library for user friendly website creation."}
                            location={"SF, CA"}
                            dates={"Mar 2024 – Present"}/>
              </div>

              <div className={"justify-self-center mr-0 hover:z-10"}>
                  <Projects className={""}
                            projectName={"Software Engineering Intern @ MemVerge"}
                            projectInfo={"Optimized CRIU's async snapshotting process to reduce RAM usage and increase the speed of RAM reclamation. Collaborated with mentor to learn the codebase and made contributions to MemVerge's proprietary fork of CRIU for SpotSurfer."}
                            location={"Sunnyvale, CA"}
                            dates={"May – Aug 2022"}/>
              </div>

              <div className={"justify-self-center mr-0 hover:z-10"}>
                  <Projects className={""}
                            projectName={"Data Engineering Intern @ Bosch"}
                            projectInfo={"Learned Spark and Scala to contribute a module to Bosch's data pipeline for businesses. Committed to their GitHub repo in production. Preformed incremental data reading and updates to save resources. Learned codebase to be able to create a module as well as run and write tests."}
                            location={"Sunnyvale, CA"}
                            dates={"May – Aug 2022"}/>
              </div>

              <div className={"justify-self-center hover:z-10"}>
              <Projects
                  projectName={"Engineering Intern @ Clear Labs"}
                  projectInfo={"Collaborated with state health departments to establish data transmission protocols from Clear Labs. Set up data transmission protocols for each state using UPHN Lite, PHNIMS, SSH, and SFTP. Designed and implemented automated testing for various front-end components."}
                  location={"San Mateo, CA"}
                  dates={"Jun – Aug 2021"}/>
              </div>

              <div className={"justify-self-center md:mt-0 lg:mt-10 xl:mt-0 hover:z-10"}>
              <Projects
                  projectName={"PathFX Research Assistant"}
                  projectInfo={"Developed Python code to automatically test various versions of PathFX bioinformatics software (PathFXweb.net). Used Python and Matplotlib for data analysis and visualization."}
                  location={"Remote"}
                  dates={"Jan – Mar 2021"}/>
              </div>
          </div>
          <div className={"font-face-Menlo pt-10 pl-5 md:pl-20 pb-8 text-3xl md:text-4xl"}>Projects</div>
          <div className={"grid grid-rows-1 lg:grid-cols-2 xl:grid-cols-3 md:pl-20 md:w-fit gap-5 lg:gap-35 xl:gap-25"}>
              <div className={"justify-self-center hover:z-10"}>
                  <Projects
                      projectName={"8084 Cafe"}
                      projectInfo={"Web application for a menu and a coupon system using React + Tailwind CSS for\n" +
                          "frontend, Firebase for backend. SwiftUI macOS + iOS app to create user specified coupons.\n" +
                          "Pop-up location at 626 Night Market in Alameda. " +
                          "Sold boba to over 500 customers."}
                      location={""}
                      dates={""}/>
              </div>

              <div className={"justify-self-center hover:z-10"}>
                  <Projects
                      projectName={"Personify.bio"}
                      projectInfo={"Built a web application leveraging the Spotify API. The user logs in using Spotify, and a unique photo card and musical personality based on their recent\n" +
                          "listening history is then generated that the user can share. Flask used as backend. Project is Hosted on Heroku. Website is created using HTML and CSS."}
                      location={""}
                      dates={""}/>
              </div>
          </div>
      <Footer/>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
